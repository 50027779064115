import React, { ReactElement } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';

// FIXME: Add typing for Gatsby GrapthQL queries
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ElementsPage: React.FC<any> = (): ReactElement => (
  <StaticQuery
    query={indexQuery}
    render={data => {
      const { t } = useTranslation();
      const siteTitle = data.site.siteMetadata.title;
      const categories = data.allStrapiCategories.nodes;
      return (
        <Layout title={siteTitle}>
          <SEO
            title={'Recetas'}
            description={'Listado de recetas por categoría'}
            keywords={['recetas', 'categorías']}
          />
          <div className="section-page-tags wrap">
            <div className="page-tags-wrap flex">
              <div className="page-tags-title">
                <h4>{t('CATEGORIES_PAGE_TITLE')}</h4>
              </div>
              {categories.map((category, index) => {
                return (
                  index < 4 && (
                    <div className="page-tag-wrap top is-image" key={category.id}>
                      <AniLink fade duration={1.2} to={category.fields.path} className="item-link-overlay">
                        <div
                          className="page-tag-image"
                          style={{
                            backgroundImage: `url(${
                              category.thumbnail ? category.thumbnail.childImageSharp.fluid.src : ''
                            })`,
                          }}
                        ></div>
                        <h2>{category.name}</h2>
                        <p>{category.description}</p>
                      </AniLink>
                    </div>
                  )
                );
              })}
              <div className="page-tags-title">
                <h4>{t('CATEGORIES_PAGE_SUBTITLE')}</h4>
              </div>
              {categories.map((category, index) => {
                return (
                  index >= 4 && (
                    <div className="page-tag-wrap other is-image" key={category.id}>
                      <AniLink fade duration={1.2} to={category.fields.path} className="item-link-overlay">
                        <div
                          className="page-tag-image"
                          style={{
                            backgroundImage: `url(${
                              category.thumbnail ? category.thumbnail.childImageSharp.fluid.src : ''
                            })`,
                          }}
                        ></div>
                        <h2>{category.name}</h2>
                      </AniLink>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </Layout>
      );
    }}
  />
);

const indexQuery = graphql`
  query topCategories {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiCategories(sort: { fields: recipes, order: DESC }) {
      nodes {
        id
        name
        description
        fields {
          path
        }
        thumbnail {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;

export default ElementsPage;
